import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import Alert from 'react-s-alert'
import 'react-s-alert/dist/s-alert-default.css'
import 'react-s-alert/dist/s-alert-css-effects/stackslide.css'

import Head from 'components/Head'
import urls from 'constants/urls'
import 'styles/app.scss'

export default function Layout({ children, className }) {
  return (
    <div className={classNames('Layout', className)}>
      <Head isLayout preconnect={urls.PRECONNECT} />
      <Alert effect="stackslide" position="top" timeout={5000} />
      {children}
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.any.isRequired,
  className: PropTypes.string,
}
