const API_ROOT = process.env.GATSBY_API_URL
const API = `${API_ROOT}/api/`
const API_PHONE_VERIFICATION = `${API}verify-phone/`
const API_BILLS = `${API}billpay/`

export default {
  API,
  API_ROOT,

  API_PHONE_VERIFICATION_CHECK: `${API_PHONE_VERIFICATION}check`,
  API_PHONE_VERIFICATION_SIGN_OUT: `${API_PHONE_VERIFICATION}logout`,
  API_PHONE_VERIFICATION_START: `${API_PHONE_VERIFICATION}start`,
  API_PHONE_VERIFICATION_STATUS: `${API_PHONE_VERIFICATION}status`,

  API_PAYMENT_METHOD: `${API_BILLS}payment-method/`,
  API_PAYMENT: `${API_BILLS}payment-request/`,
  API_RECIPIENT: `${API_BILLS}recipient/`,

  SIGN_OUT: `${API_ROOT}/verify-phone/logout`,

  GOOGLE_MAPS_API: 'https://maps.googleapis.com',

  IRS_EIN: 'https://www.irs.gov/businesses/small-businesses-self-employed/employer-id-numbers',

  NMI_CDN: 'https://secure.safewebservices.com',

  SMARTY_STREETS: 'https://us-street.api.smartystreets.com',

  WOMPLY_SIGN_UP:
    process.env.GATSBY_FR_RELEASE_LEVEL !== 'prod'
      ? 'https://womply1dev.wpengine.com/account-create/'
      : 'https://www.womply.com/account-create/',

  PRECONNECT: [
    API_ROOT,
    'https://www.facebook.com',
    'https://connect.facebook.net',
    'https://www.google.com',
    'https://www.googletagmanager.com',
    'https://www.google-analytics.com',
    'https://api-js.mixpanel.com',
  ],
}
