import React from 'react'

import paths from 'constants/paths'
import Utility from 'templates/Utility'

export default function ServerError() {
  return (
    <Utility
      subtext="Darn, something went wrong!"
      button="Go to home"
      buttonTo={paths.HOME}
      className="ErrorServer">
      Sorry for the inconvenience, we will notify our engineering team and resolve the issue as soon
      as possible.
    </Utility>
  )
}
